import React from 'react';
import './OurStory.scss';
import { Link } from 'react-scroll';
//import {Link as PageLink} from 'react-router-dom'
import cook from '../../assets/cook_image.jpeg';

const OurStory = () => {
  return (
    <div className='ourstory_container' name='about'>
      <div className="left_image">
        <img src={cook} alt="cook" />
      </div>

      <div className="right_content">
        <h1>Our Story</h1>
        <p>
          Welcome to our café located in the vibrant Seward neighbourhood of Minneapolis. We pride ourselves on offering a wide variety of beverages and cuisine that are sure to please any palate. Whether you're in the mood for a hot cup of coffee, refreshing tea, or a tasty smoothie, we have something for everyone.<br />
          In addition to our beverages, we offer a delicious selection of sandwiches, bowls, and crepes made with only the highest quality ingredients. Our Somali-style sambusas are a particular customer favorite, packed with flavorful fillings and expertly prepared.<br />
        </p>
        {/* <PageLink to='/' className='contact_btn'>Contact Us</PageLink> */}
        <a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="contact_btn link">Contact Us</Link></a>
      </div>
    </div>
  )
}

export default OurStory;