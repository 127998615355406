import React from 'react';
import './Navbar.scss';
import { Link } from 'react-scroll';
import logo from '../../assets/logo.png';

const Navbar = () => {
  return (
    <div className='navbar_container'>
      <div className="logo">
        <img src={logo} alt="logo" />
        </div>

        <ul className="nav_menu">
          <li><a href="/"><Link activeClass="active" to="home" spy={true} smooth={true} duration={700} className="link">Home</Link></a></li>
          <li><a href="/"><Link activeClass="active" to="menu" spy={true} smooth={true} duration={700} className="link">Menu</Link></a></li>
          <li><a href="/"><Link activeClass="active" to="about" spy={true} smooth={true} duration={700} className="link">About</Link></a></li>
          <li><a href="/"><Link activeClass="active" to="event" spy={true} smooth={true} duration={700} className="link">Catering</Link></a></li>
          <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="link">Contact Us</Link></a></li>
        </ul>
    </div>
  )
}

export default Navbar;