import Hero from './components/Hero/Hero';
import OurStory from './components/OurStory/OurStory';
import Menu from './components/Menu/Menu';
import Eventt from './components/Eventt/Event';
import Footer from './components/Footer/Footer';
//import CarouselMenu from './components/Menu/CarouselMenu';
import MenuImage from './components/Menu/MenuImage';
import Contact from './components/Contact/Contact';
import VIewMore from './components/Menu/VIewMore';


function App() {
  return (
    <div className="App">
      <Hero />
      <OurStory />
      <Menu />
      {/* <CarouselMenu /> */}
      <MenuImage />
      <VIewMore />
      <Eventt />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;