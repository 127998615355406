import React from 'react';
import './Contact.scss';

function Contact() {
  return (
    <div className='contact_container' name='contact'>
        <form action="#">
            <h1>Contact Us</h1>
            <input type="text" id='name' name='name' placeholder='Enter Your Name' autoComplete='false' required />
            <input type="email" id='email' name='email' placeholder='Enter Your Email' autoComplete='false' required />
            <textarea name="message" id="message"  rows="4" placeholder='Message' required />
            <input type="submit" id='submit' name='submit' />
        </form>
    </div>
  )
}

export default Contact;