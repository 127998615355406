import React from 'react';
import './Hero.scss';
import {Link as PageLink} from 'react-router-dom'
import {FaFacebook, FaTwitter, FaInstagram, FaWhatsapp} from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';

// import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const Hero = () => {
  return (
    <>
    <div className='hero_container' name='home'>
      <Navbar />

      <div className="text_container">
        <h1><span>Capitol Cafe</span> & <br />  Catering</h1>
        <p>Healthy East African-American infusion food</p>
        <PageLink to='https://capitolcafe.menufy.com/' target='_blank' className='order_btn'>Order Now</PageLink>
      </div>

      <div className="social_link">
        <PageLink to='/' className='icon'><FaFacebook /></PageLink>
        <PageLink to='/' className='icon'><FaTwitter /></PageLink>
        <PageLink to='/' className='icon'><FaInstagram /></PageLink>
        <PageLink to='/' className='icon'><FaWhatsapp /></PageLink>
      </div>
    </div>

    {/* <WhatsAppWidget
			phoneNo="7699809537"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={true}
			autoOpenTimer={5000}
			messageBox={true}
			iconSize="60"
			iconColor="white"
			iconBgColor="green"
			headerIcon="https://capitolcafe.com.my/wp-content/uploads/2021/01/cropped-logo-capitol-3.png"
			headerIconColor="pink"
			headerTxtColor="white"
			headerBgColor="green"
			headerTitle="Capitol Executive"
			headerCaption="Online"
			bodyBgColor="#f4f4f4"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /> How can I help you?</>}
			footerBgColor="#999"
			placeholder="Type a message.."
			btnBgColor="#F0C447"
			btnTxt="Start Chat"
			btnTxtColor="black"
	/> */}

  </>
  )
}

export default Hero;