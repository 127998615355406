import React from 'react';
import './VIewMore.scss'

function VIewMore() {
  return (
    <div className='view_btn'>
        <a href="https://capitolcafe.menufy.com/" target="_blank" rel="noreferrer">View More</a>
    </div>
  )
}

export default VIewMore;