import React from "react";
import "./Menu.scss";

const Menu = () => {
  return (
    <div className="menu_container" name='menu'>
      <div className="top_content">
        <h1>Menu</h1>
        <p>
          Start your day with our freshly brewed coffee or tea, or try one of our refreshing smoothies made with a variety of fruits and vegetables. Our sandwiches are made with freshly baked bread and filled with a variety of meats, cheeses, and veggies, or try our popular bowls filled with grains, veggies, and proteins for a healthy and filling meal.
        </p>
      </div>

      
    </div>
  );
};

export default Menu;
