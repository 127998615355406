import React from 'react';
import './Event.scss';
import { Link } from 'react-scroll';

import event1 from '../../assets/event1.jpeg'
import event2 from '../../assets/event2.png'
import event3 from '../../assets/event3.png'
import event4 from '../../assets/event4.jpeg'

const Event = () => {
  return (
    <div className='event_container' name='event'>
      <h1>CATERING</h1>

       <div className="image_wrapper">
            <div className="left_img" >
                <img src={event1} alt="event1" />
                <div className="card_body">
                  {/* <h1 className='card_title'>Corporate Events</h1> */}
                  <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="card_title link">Corporate Events</Link></a></li>
                </div>
            </div>

            <div className="left_img" >
                <img src={event2} alt="event2" />
                <div className="card_body">
                  {/* <h1 className='card_title'>Social Events</h1> */}
                  <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="card_title link">Social Events</Link></a></li>
                </div>
            </div>
            <div className="left_img" >
                <img src={event3} alt="event3" />
                <div className="card_body">
                  {/* <h1 className='card_title'>Political Events</h1> */}
                  <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="card_title link">Political Events</Link></a></li>
                </div>
            </div>
            <div className="left_img" >
                <img src={event4} alt="event4" />
                <div className="card_body">
                  {/* <h1 className='card_title'>Birthday Parties</h1> */}
                  <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="card_title link">Birthday Parties</Link></a></li>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Event;