import React from 'react';
import './MenuImage.scss';

import TandooriChicken from '../../assets/1-1.jpg';
import MalawaxWrapChicken from '../../assets/2.jpg';
import Sambusafilledwithmeat from '../../assets/4.jpg';
import MalwaxWrapEgg from '../../assets/9.jpg';

import MangoBananaSmoothie from '../../assets/3.jpg';
import MalawaxFlavoredCrepeStrawberry from '../../assets/7-1.jpg';
import ChaiLatte from '../../assets/11-1.jpg';

function MenuImage() {
  return (
    <>
      <div className='image_container'>
        <div className="first_col">
          <div className="item">
            <img src={TandooriChicken} alt="chickenTandoori" />
            <h3>Tandoori Chicken</h3>
            <p>$8.99</p>
          </div>

          <div className="item">
            <img src={MalawaxWrapChicken} alt="MalawaxWrapChicken" />
            <h3>Malawax Wrap Chicken</h3>
            <p>$8.99</p>
          </div>
          
          <div className="item">
            <img src={Sambusafilledwithmeat} alt="Sambusafilledwithmeat" />
            <h3>Sambusa filled with meat</h3>
            <p>$2.00</p>
          </div>

          <div className="item">
            <img src={MalwaxWrapEgg} alt="MalwaxWrapEgg" />
            <h3>Malwax Wrap - Egg</h3>
            <p>$12</p>
          </div> 
        </div>

        <div className="sec_col">
          <div className="item">
            <img src={MangoBananaSmoothie} alt="MangoBananaSmoothie" />
            <h3>Mango Banana Smoothie</h3>
            <p>$4.99</p>
          </div>

          <div className="item">
            <img src={MalawaxFlavoredCrepeStrawberry} alt="MalawaxFlavoredCrepeStrawberry" />
            <h3>Malawax Flavored Crepe - Strawberry</h3>
            <p>$1.89</p>
          </div>
          
          <div className="item">
            <img src={ChaiLatte} alt="ChaiLatte" />
            <h3>Chai Latte</h3>
            <p>$2.99</p>
          </div> 
        </div>

        
      </div>
    </>
  )
}

export default MenuImage;