import React from 'react';
import './Footer.scss';
import { Link } from 'react-scroll';
import {FaFacebook, FaInstagram, FaTwitterSquare, FaYoutube} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer_container'>
      <ul className="footer_nav-menu">
          <li><a href="/"><Link activeClass="active" to="menu" spy={true} smooth={true} duration={700} className="link">Menu</Link></a></li>
          <li><a href="/"><Link activeClass="active" to="about" spy={true} smooth={true} duration={700} className="link">About</Link></a></li>
          <li><a href="/"><Link activeClass="active" to="event" spy={true} smooth={true} duration={700} className="link">Events</Link></a></li>
          <li><a href="/"><Link activeClass="active" to="contact" spy={true} smooth={true} duration={700} className="link">Contact</Link></a></li>
      </ul>

      <div className="social_icon">
        <h1>Capitol Cafe</h1>
        <div className="icons">
          <a href="/"><FaFacebook className='icon' /></a>
          <a href="/"><FaInstagram className='icon' /></a>
          <a href="/"><FaTwitterSquare className='icon' /></a>
          <a href="/"> <FaYoutube className='icon' /></a>
        </div>
      </div>

      <form className="contact" name='footer'>
        <input type="email" className='email' name='email' id='email' autoComplete='off' placeholder='Enter your e-mail' required />
        <input type="submit" className='submit' name='submit' value='Submit' />
      </form>
    </div>
  )
}

export default Footer;